*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body
{
    overflow: hidden;
}

.experience
{
    position: fixed;
    width: 100vw;
    height: 100vh;
}